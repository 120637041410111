<ul class="small services-tabs desktop-only">
	<li (click)="changeTab('facial')" [ngClass]="tab=='facial' ? 'active' : ''"><button>Facials</button></li>
	<!-- <li (click)="changeTab('addon')" [ngClass]="tab=='addon' ? 'active' : ''"><button>Add-ons</button></li> -->
	<li *ngIf="hasServices('eyebrows')" (click)="changeTab('eyebrows')" [ngClass]="tab=='eyebrows' ? 'active' : ''"><button>Eyebrows</button></li>
	<li *ngIf="hasServices('Microchanneling')" (click)="changeTab('microchanneling')" [ngClass]="tab=='microchanneling' ? 'active' : ''"><button>Microchanneling</button></li>
	<li *ngIf="hasServices('Chemical Peels')" (click)="changeTab('peels')" [ngClass]="tab=='peels' ? 'active' : ''"><button>Chemical Peels</button></li>
</ul>

<ul class="mobile-only mobile-services-tabs">
	<li (click)="changeTab('facial')" [ngClass]="tab=='facial' ? 'active' : ''"><button>Facials</button></li>
	<!-- <li (click)="changeTab('addon')" [ngClass]="tab=='addon' ? 'active' : ''"><button>Add-ons</button></li> -->
	<li *ngIf="hasServices('eyebrows')" (click)="changeTab('eyebrows')" [ngClass]="tab=='eyebrows' ? 'active' : ''"><button>Eyebrows</button></li>
	<li *ngIf="hasServices('Microchanneling')" (click)="changeTab('microchanneling')" [ngClass]="tab=='microchanneling' ? 'active' : ''"><button>Microchanneling</button></li>
	<li *ngIf="hasServices('Chemical Peels')" (click)="changeTab('peels')" [ngClass]="tab=='peels' ? 'active' : ''"><button>Chemical Peels</button></li>
</ul>