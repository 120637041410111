
    <a *ngIf="cart?.selectedItems?.length" [ngClass]="cart?.selectedItems?.length ? 'active' : '' " class="data-ui cart" href="javascript:void(0)"
     (click)="bookingService.toggleMobileCart()" >
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-fill" viewBox="0 0 16 16">
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
            </svg>
        </span>
    </a>
    <a class="data-ui sign-in" href="javascript:void(0)" [ngClass]="user ? 'active' : '' " (click)="menuToggle()">
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
            </svg>
        </span>
    </a>
    <ul class="loggedin-menu user-pop" *ngIf="showSubMenu">
        <li *ngIf="user"><b>Hi, {{user ? user.firstName : ''}}</b></li>
        <li *ngIf="user" routerLink="/dashboard">My Dashboard</li>
        <li routerLink="/booking">Book a Facial</li>
        <li routerLink="/nutrition">Book a Nutrition</li>
        <li routerLink="/membership/selection">Buy Membership</li>
        <li routerLink="/giftcards">Buy Gift Card</li>
        <li routerLink="/packages">Buy Package</li>
        <li *ngIf="user" (click)="logout()">Sign Out</li>
        <li *ngIf="!user" routerLink="/auth/login">Sign In</li>
    </ul>
