<div class="popup-screen"></div>

<div class="popup card-details">
	<button class="close" (click)="serviceModalRef.close()"><span class="accessibility">Close Popup</span></button>
	
	<img [src]="'https://blvd.silvermirror.com/assets/cover-' + service.id + '.png'" alt=""/>
	
	<article>
		<ngx-simplebar >
			<h2 class="heading-6 flex-container"><span class="service-name" [innerHTML]="getServiceName(service?.name)"></span> <span class="heading-6 small">{{sharedService.formatPrice(service.listPrice) | currency:'USD':'symbol' : '1.0-0'}}</span></h2>
			<span class="pill fill outline">{{service?.category.replace("Facials ", "")}}</span>
			<span class="badge-img"><img [src]="'https://blvd.silvermirror.com/assets/badge-' + service.id + '.png'" /></span>
			
			<p class="x-small skin-types"><span class="pill">Recommended for</span> {{sharedService.getSkinType(service?.description)}}</p>
			<p class="x-small service-description" [innerHTML]="sharedService.getDescriptionOnly(service?.description)"></p>

			<p *ngIf="this.isBaseServiceAdded()" style="margin-bottom: 16px; font-style: italic;" class="x-small">Facials, Eyebrows, Microchanneling and Chemical Peels cannot be performed together.</p>
		</ngx-simplebar>

		<!-- Add Service Feature Commented -->
		<!-- <button *ngIf="!this.isBaseServiceAdded()" class="btn black" (click)="addService()">Add Service</button>
		<button *ngIf="this.isBaseServiceAdded()" class="btn black" (click)="addAddons()">Add-on</button> -->
		<button *ngIf="!service.selected" class="btn black" (click)="addService()">Add Service</button>
		<button *ngIf="service.selected" class="btn blue" (click)="addService()">
			<span class="added-icon"></span>
			Service Added
		</button>
	
	</article>
</div>