import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ServicesComponent } from "./services/services.component";
import { LocationComponent } from "./location/location.component";
import { WhosComingComponent } from "./whos-coming/whos-coming.component";
import { SchedulingComponent } from "./scheduling/scheduling.component";
import { ReviewComponent } from "./review/review.component";
import { CongratsComponent } from "./congrats/congrats.component";
import { LocationGuard } from "../guards/location.guard";
import { AddonsComponent } from "./addons/addons.component";

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: "booking",
        children: [
          {
            path: "location",
            children: [
              {
                path: "service/:service/coupon/:coupon",
                component: LocationComponent,
              },
              {
                path: "coupon/:coupon",
                component: LocationComponent,
              },
              {
                path: "service/:service",
                component: LocationComponent,
              },
              {
                path: ":location",
                component: LocationComponent,
              },
              {
                path: ":location/service/:service",
                component: LocationComponent,
              },
              {
                path: ":location/coupon/:coupon",
                component: LocationComponent,
              },
              {
                path: ":location/service/:service/coupon/:coupon",
                component: LocationComponent,
              },
              {
                path: "",
                pathMatch: "full",
                component: LocationComponent
              }
            ]
          },
          {
            path: "whoscoming",
            component: WhosComingComponent,
            canActivate: [LocationGuard]
          },
          {
            path: "services",
            component: ServicesComponent,
            canActivate: [LocationGuard]
          },
          {
            path: "add-ons",
            component: AddonsComponent,
            canActivate: [LocationGuard]
          },
          {
            path: "schedule",
            component: SchedulingComponent,
            canActivate: [LocationGuard]
          },
          {
            path: "review",
            component: ReviewComponent,
            canActivate: [LocationGuard]
          },
          {
            path: "congrats",
            component: CongratsComponent,
          },
          // { path: "location", redirectTo: "location/service" },
          { path: "", redirectTo: "location", pathMatch: 'full' },
        ],
      },
    ], {useHash: false}),
  ],
  exports: [RouterModule],
})
export class BookingRoutingModule {}
