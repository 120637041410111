<ul class="appointment-list" [ngClass]="appointment?.guestsAppointment?.appointments?.length ? 'guest' : '' ">
	<li class="flex-container" (click)="toggleMobileList = !toggleMobileList" [ngClass]="toggleMobileList ? 'active' : '' ">
		<!--Date-->
		
		<div class="date">
			<span class="mobile-only">{{appointment?.startAt | date:'EEE':'EST'}},</span>
			<span class="ui-date month">{{appointment?.startAt | date:'MMM':'EST'}}</span>
			<span class="heading-5 day">{{appointment?.startAt | date:'d':'EST'}}</span>
			<span class="ui-date year">{{appointment?.startAt | date:'y':'EST'}}</span>
			<span class="pill fill small gray">&#64;{{sharedService.removeLastTimezone(appointment?.startAt) | date:'hh:mm a'}}</span>
			<span class="guest-mobile mobile-only">Group</span>
		</div>
		
		<!--Services-->
		
		<div class="services">
			<h3 class="pill">Services (ME)</h3>
			
			<ul class="x-small">
				<li [ngClass]="isAddon(service) ? 'addon' : 'service'" *ngFor="let service of appointment.appointmentServices | sortAppointmentServices">{{service?.service?.name}} <span *ngIf="isAddon(service)" class="pill fill small">Add on</span></li>
				<!-- <li *ngFor="let addon of appointment.appointmentServiceOptions" class="addon">{{addon?.name}} <span class="pill fill small">Add on</span></li> -->
			</ul>
		</div>
		
		<!--Details-->
		
		<div class="details">
			<h3 class="pill">Details</h3>
			
			<ul class="x-small">
				<li class="esthetician">{{appointment.appointmentServices[0]?.staff?.firstName+' '+appointment?.appointmentServices[0]?.staff?.lastName[0]+'.'}}</li>
				<li *ngIf="activeTab == 1" class="location">{{appointment?.location?.address?.city}} - {{appointment?.location?.name}}</li>
				<li>Status - <span class="pill fill small gray">{{appointment?.state}}</span></li>
			</ul>
		</div>
		
		<!--Actions-->
		
		<div class="actions">
				<span *ngIf="rebook" class="pill rebook">Quick Rebook</span>
				<a *ngIf="rebook" class="btn black small" (click)="rebookAppointment()">Search Availability</a>
				<a *ngIf="activeTab == 2 && zoomLink && appointmentType=='upcoming'" class="btn black small" target="_blank" [href]="zoomLink">Join Meeting</a>
				<!-- <span class="pill rebook">Quick Rebook</span>
				<a class="btn black small" (click)="rebookAppointment()">Search Availability</a> -->
				
				<span (click)="navigateToReschedule()"><a *ngIf="!zoomLink && canReschedule && appointment?.reschedulable && appointment?.state != 'FINAL' && !appointment?.guestsAppointment" class="btn black small reschedule">Reschedule</a></span>
				<span *ngIf="cancel && appointment?.state != 'FINAL'" (click)="cancelBooking()"><a class="text-link">{{appointment?.guestsAppointment ? 'Cancel Group Appointment' : 'Cancel'}}</a></span>
				<!-- <p class="xx-small info" *ngIf="cancel && appointment?.state != 'FINAL'">For group cancellation, Please contact our support team.</p> -->
		</div>

		<!-- With Guset -->
		<ul class="appointment-list" *ngIf="appointment?.guestsAppointment?.appointments?.length">
			<li class="flex-container" *ngFor="let apt of appointment?.guestsAppointment.appointments" (click)="toggleMobileList = !toggleMobileList" [ngClass]="toggleMobileList ? 'active' : '' ">
				<!--Date-->
				<div class="date desktop-only">
					<!-- <span class="mobile-only">{{apt?.startAt | date:'EEEE':'EST'}},</span>
					<span class="ui-date month">{{apt?.startAt | date:'MMMM':'EST'}}</span>
					<span class="heading-5 day">{{apt?.startAt | date:'d':'EST'}}</span>
					<span class="ui-date year">{{apt?.startAt | date:'y':'EST'}}</span>
					<span class="pill fill small gray">@{{sharedService.removeLastTimezone(apt?.startAt) | date:'hh:mm a'}}</span> -->
				</div>
				
				<!--Services-->
				
				<div class="services">
					<h3 class="pill">Services ({{apt?.client?.name}})</h3>
					
					<ul class="x-small">
						<li [ngClass]="isAddon(service) ? 'addon' : 'service'" *ngFor="let service of apt?.appointmentServices">{{service?.service?.name}} <span *ngIf="isAddon(service)" class="pill fill small">Add on</span></li>
						<!-- <li *ngFor="let addon of apt.appointmentServiceOptions" class="addon">{{addon?.name}} <span class="pill fill small">Add on</span></li> -->
					</ul>
				</div>
				
				<!--Details-->
				
				<div class="details">
					<h3 class="pill">Details</h3>
					
					<ul class="x-small">
						<li class="esthetician">{{apt?.appointmentServices[0]?.staff?.firstName+' '+apt?.appointmentServices[0]?.staff?.lastName[0]+'.'}}</li>
						<li class="location">{{appointment?.location?.address?.city}} - {{appointment?.location?.name}}</li>
						<li>Status - <span class="pill fill small gray">{{appointment?.state}}</span></li>
					</ul>
				</div>
				
				<!--Actions-->
				
				<div class="actions desktop-only">
						<!-- <span *ngIf="!appointment?.reschedulable" class="pill rebook">Quick Rebook</span>
						<a *ngIf="!appointment?.reschedulable" class="btn black small" href="#">Search Availability</a> -->
						
						<!-- <span (click)="navigateToReschedule()"><a *ngIf="appointment?.reschedulable && appointment?.state != 'FINAL'" class="btn black small reschedule">Reschedule</a></span> -->
						<!-- <span *ngIf="cancel && appointment?.state != 'FINAL'" (click)="cancelBooking()"><a class="text-link">Cancel</a></span> -->
						<!-- <p class="xx-small info" *ngIf="cancel && appointment?.state != 'FINAL'">For group cancellation, Please contact our support team.</p> -->
				</div>
			</li>
		</ul>
		<div class="actions mobile-only">
			<span *ngIf="rebook" class="pill rebook">Quick Rebook</span>
			<a *ngIf="rebook" class="btn black small" (click)="rebookAppointment()">Search Availability</a>
			<a *ngIf="activeTab == 2 && zoomLink && appointmentType=='upcoming'" class="btn black small" target="_blank" [href]="zoomLink">Join Meeting</a>
			<span (click)="navigateToReschedule()"><a *ngIf="!zoomLink && appointment?.reschedulable && appointment?.state != 'FINAL' && !appointment?.guestsAppointment" class="btn black small reschedule">Reschedule</a></span>
			<span *ngIf="cancel && appointment?.state != 'FINAL'" (click)="cancelBooking()"><a class="text-link">{{appointment?.guestsAppointment ? 'Cancel Group Appointment' : 'Cancel'}}</a></span>
		</div>
	</li>
</ul>