import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { RebookService } from "src/app/dashboard/rebook.service";
import { Notification, NotificationService } from "src/app/notification.service";
import { SharedService } from "src/app/shared-component/shared.service";
import { BookingService } from "../booking.service";
import { ADDON_CATEGORIES } from "src/app/constants/constants";

@Component({
  selector: "app-location",
  templateUrl: "./location.component.html",
  styleUrls: ["./location.component.scss"],
})
export class LocationComponent implements OnInit {

  currentIndex: any;
  isActive = false;
  lastAppointment:any = [];
  paramLocation!:string;

  constructor(private route: ActivatedRoute, public rebookService:RebookService, 
    public authService:AuthService, public bookingService:BookingService, 
    private notificationService:NotificationService, public sharedService:SharedService, private router:Router) {
    this.getLocations();
    authService.$AuthUser.value ? this.rebookService.getLastAppointment() : null;
    rebookService.$lastAppointment.subscribe(data=>{
      if(data && data.length){
        this.lastAppointment = data;
        this.filterLastAppointment();
      }
    })
    let lastRes: any;

 /*  this.bookingService.activeServicesByLocation$.subscribe((res: any) => {
    if (res !== lastRes) {
      lastRes = res;
      this.bookingService.activeServicesByLocation$.next(res);
    }
  }); */
  }

  filterLastAppointment(){
    const baseService = this.lastAppointment[0].node.appointmentServices.filter((service:any)=>{
      return !ADDON_CATEGORIES.includes(service.service.category.name.toUpperCase());
    })
    const addonsServices = this.lastAppointment[0].node.appointmentServices.filter((service:any)=>{
      return ADDON_CATEGORIES.includes(service.service.category.name.toUpperCase());
    })
    this.lastAppointment[0].node.baseService = baseService;
    this.lastAppointment[0].node.addonsServices = addonsServices;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const couponCode = params['coupon'];
      const serviceName = params['service'];
      this.paramLocation = params['location'];
      couponCode ? this.sharedService.setLocalStorageItem('coupon', couponCode) : null;
      serviceName ? this.sharedService.setLocalStorageItem('serviceName', serviceName) : null;
    });
  }

  getLocations(){
   // if(!this.bookingService.locationList$.value.length){
      this.bookingService.getLocations().subscribe((res: any) => {
        if(!res.errors){
          this.bookingService.locationList$.next(res.data.locations.edges);
          this.setParamLocation()
        }else{
          this.sharedService.showNotification("Error", res.errors[0].message);
        }
      });
   // }
  }

  setParamLocation(){
    if(this.paramLocation){
      const loc = this.bookingService.locationList$.value.filter((loc:any)=> loc.node.name.replaceAll(' ','-').toLowerCase() == this.paramLocation.replaceAll(' ','-').toLowerCase())
      loc.length ? this.createCart(loc[0].node.id) : null;
    }
  }
  
  expand(index: any) {
    if (this.currentIndex === index) {
      this.currentIndex = null;
      return;
    }
    this.currentIndex = index;
  }
  
  locationSelected(id: any) {
    const location = this.sharedService.getLocalStorageItem("selectedLocation");
    return location && location == id ? "active" : '';
  }

  locationCatSelected(location:{key:string, value:Array<any>}){
    const selectedLocation = this.sharedService.getLocalStorageItem("selectedLocation");
    const filteredLoc = location.value.filter((loc:any)=>{
      return selectedLocation == loc.id
    });
    if(filteredLoc.length){
      return true
    }else{
      return false
    }
  }
  
  createCart(id?: any | null){
    this.bookingService.ngxUiLoaderService.start();
    this.bookingService.createCart(id).subscribe((res:any)=>{
      if(!res.errors){
        this.sharedService.setLocalStorageItem('selectedLocation', id);
        this.sharedService.setLocalStorageItem('cartId', res.data.createCart.cart.id);
        this.bookingService.getCartDetail().subscribe((res:any)=>{
          if(!res.errors){
            this.bookingService.clientCart$.next(res.data.cart);
            this.router.navigateByUrl('/booking/whoscoming');
          }
          this.bookingService.ngxUiLoaderService.stop();
        });
      }else{
        this.sharedService.showNotification("Error", res.errors[0].message);
        this.bookingService.ngxUiLoaderService.stop();
      }
    });
  }
  public loadingMap: { [key: string]: boolean } = {};

  selectLocation(id: any) {
    // Set loading to true for the specific location
    this.bookingService.ngxUiLoaderService.start();
    this.loadingMap[id] = true;
    const locationId = this.sharedService.getLocalStorageItem('selectedLocation');
    const cartId = this.sharedService.getLocalStorageItem('cartId');
    if(cartId){
    this.bookingService.getCartDetail().subscribe(
      async (res: any) => {
        this.bookingService.clientCart$.next(res.data.cart);
        try {
          if (locationId == id && cartId) {
            await this.router.navigateByUrl('/booking/whoscoming');
          } else if (locationId != id && res.data?.cart?.selectedItems.length > 0) {
            this.bookingService.ngxUiLoaderService.stop();
            const message = "Changing the location may affect service or add-on availability in your cart. Do you want to continue?";
            const confirmation = await this.sharedService.locationOpenConfirmationAlert(message);
            if (confirmation) {
              await this.createCart(id);
              await this.router.navigateByUrl('/booking/whoscoming');
            } else {
              if(this.sharedService.keep_my_cart_and_continue){
                this.bookingService.ngxUiLoaderService.start();
                await this.bookingService.changeLocation(id);
                this.sharedService.setLocalStorageItem('selectedLocation', id);
                this.bookingService.ngxUiLoaderService.stop();
                this.sharedService.setLocalStorageItem('isSameService', 'false');
                await this.router.navigateByUrl('/booking/services');
              }
            }
          } else {
            this.sharedService.removeLocalStorageItem('guestSet');
            await this.createCart(id);
          }
        } catch (error) {
          console.error('An error occurred:', error);
        } finally {
          // Set loading to false for the specific location
          this.loadingMap[id] = false;
          this.bookingService.ngxUiLoaderService.stop();
        }
      },
      (error) => {
        console.error('An error occurred while fetching cart details:', error);
        // Set loading to false for the specific location in case of error
        this.bookingService.ngxUiLoaderService.stop();
        this.loadingMap[id] = false;
      }
    );
  }else{
    this.createCart(id);
  }
  
    this.sharedService.removeLocalStorageItem('isSameService');
  }
  
  
  

  skipLocation(){
    const cartId = this.sharedService.getLocalStorageItem('cartId');
    if(cartId){
      this.router.navigateByUrl('/booking/whoscoming');
    }else{
      this.createCart();
    }
    this.sharedService.removeLocalStorageItem('isSameService');
  }
}
