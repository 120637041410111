<ul class="services-list" *ngIf="services.length">
	<li *ngFor="let service of services[0].availableItems | order:category" [ngClass]="service.selected ? 'active' : ''">
		<button (click)="addService(service)" class="add-to-cart desktop-only">
            <img [src]="service.selected ? 'assets/svgs/icon-selected-check.svg' : 'assets/svgs/icon-accordion-open.svg' " />
			<!-- <?php 
				include('svgs/icon-accordion-open.svg');
				include('svgs/icon-selected-check.svg');
			?> -->
					
			<span class="accessibility">Add this service to your cart</span>
		</button>
			
		<button class="flex-container open-services-pop" (click)="serviceDetail(service)">
			<img [src]="'https://blvd.silvermirror.com/assets/thumb-' + service.id + '.png'" alt=""/>
			
			<div>
				<h2 class="heading-6" [innerHTML]="getServiceName(service.name)"></h2>
				<span class="badge-img"><img [src]="'https://blvd.silvermirror.com/assets/badge-' + service.id + '.png'" /></span>
				<p class="pill">{{sharedService.getSkinType(service?.description)}}</p>
				<span class="heading-6 small price">{{sharedService.formatPrice(service.listPrice) | currency:'USD':'symbol' : '1.0-0'}}</span>
			</div>
			
			
		</button>
	</li>
</ul>

<ul *ngIf="services.length == 0">
	<p>No service is available for selected location.</p>
</ul>