import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SharedService } from "../shared-component/shared.service";
import { BookingService } from "../booking/booking.service";
import { AuthService } from "../auth/auth.service";
import { environment } from "src/environments";
import { BehaviorSubject, of, shareReplay } from "rxjs";
import { PdfService, PDF_INTERFACE } from "./pdf.service";

const BASE_URL = environment.apiBaseURL;
const LOCATION_ID = environment.giftcard_location_id;
const PAYMENT_API_BASE_URL = environment.paymentApiBaseURL;
const GIFTCARD_DESIGN_ID = "177c95c9-c310-463e-8b08-b662535cfc53";

@Injectable({
  providedIn: "root",
})
export class PackageService {

  clientCart$: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedGiftCard: BehaviorSubject<any> = new BehaviorSubject(null);
  packageDescription: any;

  constructor(private http: HttpClient, private sharedService: SharedService, private bookingService: BookingService,
    private authService: AuthService, private pdfSevices: PdfService) {


  }

  getPackagesList() {
    return this.http.get(BASE_URL + '/package');
  }
  getGiftCards() {
    return this.http.get(BASE_URL + "/package");
  }

  createCart() {
    const payload = {
      locationID: LOCATION_ID,
      client_id: this.authService.$AuthUser.value?.authId
    };
    return this.http.post(BASE_URL + '/create_cart', payload);
  }

  cartDetail() {
    const cartID = localStorage.getItem('packageCart');
    const payload = {
      cartID: cartID,
      clientId: this.authService.$AuthUser.value?.authId
    };
    return this.http.post(BASE_URL + '/get_cart_detail', payload).pipe(shareReplay());
  }

  getCartDetail() {
    const cartId = localStorage.getItem('packageCart');
    if (cartId) {
      this.cartDetail().subscribe((res: any) => {
        if (!res.errors) {
          this.clientCart$.next(res.data.cart);
        } else {
          this.sharedService.showNotification("Error", res.errors[0].message);
        }
      });
    }
  }

  addProductToCart(itemID: any) {
    const payload = {
      "cartId": this.sharedService.getLocalStorageItem('packageCart'),
      "itemId": itemID,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL + '/add_product_to_cart', payload);
  }

  removeProductFromCart(itemId: string) {
    const payload = {
      "cartId": localStorage.getItem('packageCart'),
      "itemId": itemId
    }
    return this.http.post(BASE_URL + '/remove_item_in_cart', payload);
  }

  addGiftCard() {
    const packageDetail = localStorage.getItem('packageDetail');
    if (packageDetail) {
      var packageDetails = JSON.parse(packageDetail);
    }
    const payload = {
      clientId: this.authService.$AuthUser.value?.authId,
      cartId: localStorage.getItem('packageCart'),
      itemId: "GIFT_CARD",
      itemPrice: Math.round(packageDetails.packageAmount * 100)
    };
    return this.http.post(BASE_URL + '/add_giftcard_in_cart', payload);
  }



  setCardDesign(itemID: string) {
    const payload = {
      clientId: this.authService.$AuthUser.value?.authId,
      cartId: localStorage.getItem('packageCart'),
      itemId: itemID,
      giftCardDesignId: GIFTCARD_DESIGN_ID
    };
    return this.http.post(BASE_URL + '/set_giftcard_design', payload);
  }

  updateEmailfulfilment(data: any) {
    const payload = {
      clientId: this.authService.$AuthUser.value?.authId,
      cartId: localStorage.getItem('packageCart'),
      itemId: this.clientCart$.value.selectedItems[0].id,
      messageFromSender: data.message,
      senderName: data.senderName,
      senderEmail: data.senderEmail,
      recipientEmail: data.recipientEmail,
      recipientName: data.recipientName,
      deliveryDate: data.sendDate
    };
    return this.http.post(BASE_URL + '/update_giftcard_item_email_fulfillment', payload);
  }

  addCartOffer(offerCode: string) {
    const payload = {
      "cartId": localStorage.getItem('packageCart'),
      "offerCode": offerCode
    }
    return this.http.post(BASE_URL + '/add_cart_offer', payload);
  }

  removeCartOffer(offerId: string) {
    const payload = {
      "cartId": localStorage.getItem('packageCart'),
      "offerId": offerId
    }
    return this.http.post(BASE_URL + '/remove_cart_offer', payload);
  }

  updateClientCartInfo(client: any) {
    const payload = {
      "cartId": localStorage.getItem('packageCart'),
      "clientInfo": {
        "email": client.email,
        "firstName": client.firstName,
        "lastName": client.lastName,
        "phoneNumber": client.mobilePhone
      },
      "clientNote": client.note
    }
    return this.http.post(BASE_URL + '/update_cart_client_info', payload);
  }

  tokenizeCard(card: any) {
    const tokenize_url = PAYMENT_API_BASE_URL + '/cards/tokenize';
    const payload = {
      "card": {
        "name": card.name,
        "number": card.number,
        "cvv": card.cvv,
        "exp_month": card.expiry.substring(0, 2),
        "exp_year": card.expiry.substring(3, 7),
        "address_postal_code": card.postal_code
      }
    }
    return this.http.post(tokenize_url, payload);
  }

  addCartPaymentMethod(token: string) {
    const payload = {
      "cartId": localStorage.getItem('packageCart'),
      "select": true,
      "token": token
    }
    return this.http.post(BASE_URL + '/add_cart_card_payment_method', payload);
  }

  checkoutCart() {
    const payload = {
      "cartId": localStorage.getItem('packageCart'),
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL + '/checkout_cart', payload);
  }
  async sendPackageEmail() {
    let packageBuyer: any = localStorage.getItem('packageRecepient');
    let packageBuyers = JSON.parse(packageBuyer);
    let packageDescription = this.sharedService.getPackagepackageDescription();

    const param: PDF_INTERFACE = {
      productName: this.sharedService.getPackageName(),
      amount: "",
      services: [],
      buyerName: packageBuyers.senderName.toUpperCase(),
      buyerEmail: packageBuyers.senderEmail.toUpperCase(),
      description: this.getTextAfterShortdesc(packageDescription).replace(/<\/?[^>]+>/gi, ''),
      download: false

    }
    //console.log("Param", param);
    var pdfBase64 = await this.pdfSevices.createPDF(param, false);
    const base64Data = pdfBase64.split(',')[1] || pdfBase64;
    const formData = new FormData();
    formData.append('file', base64Data); // Append the cleaned base64 data
    const packageDetail = localStorage.getItem('packageDetail');
    if (packageDetail) {
      var packageDetails = JSON.parse(packageDetail);
    }
    const packageRecepientDetail = localStorage.getItem('packageRecepient');
    if (packageRecepientDetail) {
      var packageRecepientDetails = JSON.parse(packageRecepientDetail);
    }
    formData.append('senderName', packageRecepientDetails.senderName);
    formData.append('senderEmail', packageRecepientDetails.senderEmail);
    formData.append('packageName', packageDetails.packageName);
    formData.append('packageDescription', this.removeTextAfterShortdescWithHTML(packageDetails.packageDescription));
    return this.http.post(BASE_URL + '/send_package_email', formData);
  }
  removeTextAfterShortdesc(input: string): string {
    const shortdescIndex = input.indexOf("Shortdesc:");
    if (shortdescIndex !== -1) {
      return input.substring(0, shortdescIndex).trim().replace(/<\/?[^>]+>/gi, '');
    }
    return input.replace(/<\/?[^>]+>/gi, '');
  }
  removeTextAfterShortdescWithHTML(input: string): string {
    const shortdescIndex = input.indexOf("Shortdesc:");
    if (shortdescIndex !== -1) {
      return input.substring(0, shortdescIndex).trim();
    }
    return input;
  }
  getTextAfterShortdesc(input: string): string {
    if (!input) {
      return ''; // Return an empty string if input is null or undefined
    }

    const shortdescIndex = input.indexOf("Shortdesc:");
    if (shortdescIndex !== -1) {
      return input.substring(shortdescIndex + "Shortdesc:".length).trim();
    }

    return '';
  }
  processGiftcard(payload: any) {
    payload.locationId = LOCATION_ID;

    return this.http.post(BASE_URL + '/process_giftcard', payload);
  }
  //Added for package [START]
  processGiftvoucher(payload: any) {
    return this.http.post(BASE_URL + '/create_voucher_gift_card', payload);
  }
  //Added for package [END]
  getGiftcardCode(cart_id: string) {
    const payload = {
      "cart_id": cart_id
    }
    return this.http.post(BASE_URL + '/cart_detail_by_id', payload);
  }

  getClientByEmail(email: string) {
    return this.http.post(BASE_URL + '/get_client_by_email', { emails: [email] });
  }
}
