<!-- <button class="mobile-only cart-summary flex-container" *ngIf="cart?.selectedItems?.length"
    (click)="toggleMobileCart = !toggleMobileCart" >
    <span class="eyebrow small">Summary</span>
    <span class="cost">{{sharedService.formatPrice(this.cart?.summary?.total) | currency:'USD':'symbol'}}</span>
</button> -->
<div class="cart-info">
    <button (click)="bookingService.toggleMobileCart()" class="close-cart mobile-only"><span class="accessibility">Close
            Cart</span></button>
    <h2 class="eyebrow small flex-container"><span>Cart</span><span>{{getTotalAddedServiceCount}}
            Service{{getTotalAddedServiceCount > 1 ? 's' : ''}} Added</span></h2>

    <span *ngIf="!cart?.selectedItems?.length" class="heading-6 small empty-cart">Your cart is empty</span>

    <ul class="small">
        <li *ngIf="cart?.selectedItems?.length">
            <ul class="small" *ngFor="let groupedService of (cart?.selectedItems | orderCartItems); index as index">
                <li class="flex-container user-outer">
                    <span class="user">{{groupedService.guest ? groupedService?.guest?.label : 'Me'}} <span
                            class="counter gray">{{groupedService?.data?.length}}</span> <span
                            *ngIf="getSelectedStaffVariant(groupedService.guest) && router.url == '/booking/review'"
                            class="person">{{getSelectedStaffVariant(groupedService.guest)}} </span> </span>
                </li>
                <li class="flex-container" *ngFor="let service of groupedService.data"
                    [ngClass]="service?.item?.optionGroups?.length != 0 ? 'add-on' : ''">
                    <span class="item-info">
                        <strong>{{service.item.name}}</strong>
                        <span class="pill fill small" *ngIf="service?.item?.optionGroups?.length !=0">Add On</span>
                        <span *ngIf="sharedService.getServiceCategoryName(service, cart.availableCategories) != '' "
                            class="pill">{{sharedService.getServiceCategoryName(service,
                            cart.availableCategories)}}</span>
                    </span>

                    <span class="cost">
                        <!-- Price without location {{sharedService.formatPrice(getServicePriceWithoutLocation(service,cart)) | currency:'USD':'symbol' : '1.0-0'}} -->
                        {{sharedService.formatPrice(getServicePrice(service)) | currency:'USD':'symbol' : '1.0-0'}}

                        <span *ngIf="!loadingDelete && router.url == '/booking/services'" class="remove-service-outer">
                            <button
                                *ngIf="service?.item?.optionGroups?.length ==0 && router.url == '/booking/services' &&  ((isSameService == true) ? index == 0 : true)"
                                (click)="removeItem(service)" class="remove-service"><span class="accessibility">Remove
                                    Service</span></button>
                        </span>
                        <span *ngIf="loadingDelete && deletingServiceId === service.id && router.url == '/booking/services'" class="remove-service">
                            <div class="loader" [ngStyle]="{
                                    'width': router.url === '/booking/services' ? '22px' : 'auto',
                                    'text-align': router.url === '/booking/services' ? 'center' : 'initial',
                                    'margin-top': router.url === '/booking/services' ? '-5px' : '0'
                                  }">
                                <img src="assets/Iphone-spinner.gif">
                            </div>
                        </span>
                        <span *ngIf="!loadingDelete && router.url == '/booking/add-ons'" class="remove-addons-outer">
                            <button *ngIf="service?.item?.optionGroups?.length !=0 && 
                            router.url == '/booking/add-ons' &&  ((isSameService == true) ? index == 0 : true)"
                                (click)="removeItem(service)" class="remove-service"><span class="accessibility">Remove
                                    add-ons
                                </span></button>
                        </span>
                        <span *ngIf="loadingDelete && deletingServiceId === service.id && router.url == '/booking/add-ons'" class="remove-service">
                            <div class="loader" [ngStyle]="{
                                    'width': router.url === '/booking/add-ons' ? '22px' : 'auto',
                                    'text-align': router.url === '/booking/add-ons' ? 'center' : 'initial',
                                    'margin-top': router.url === '/booking/add-ons' ? '-5px' : '0'
                                  }">
                                <img src="assets/Iphone-spinner.gif">
                            </div>
                        </span>

                    </span>
                </li>
            </ul>

            <span class="flex-container edit-service-button">
                <button class="btn black" routerLink="/booking/services" *ngIf="router.url != '/booking/services'">Add/Edit
                Services</button>
                <button class="btn black" routerLink="/booking/add-ons" *ngIf="router.url != '/booking/services' && router.url != '/booking/add-ons'">Add/Edit<br>
                    Add-ons</button>
            </span>

        </li>

        @if((!cart?.startTime || router.url !== '/booking/review') && cart?.location){
        <li class="flex-container">
            <span class="pill location">Location</span>

            <span class="location-address">
                @if(!toggleEditLocation){
                <span>
                    <strong>{{cart?.location?.address?.city}} - {{cart?.location?.name}}</strong>
                    <address>{{cart?.location?.address?.line1}}  <br> {{ cart?.location?.address?.line2 }}</address>
                    <a *ngIf="cart?.location?.phoneNumber"
                        href="tel:{{cart?.location?.phoneNumber}}">{{sharedService.removeCountryCode(cart?.location?.phoneNumber)
                        | mask: '(000) 000-0000'}}</a>
                </span>
                }
                @if(toggleEditLocation){
                <!-- <select class="location-change" (change)="bookingService.changeLocation($event)">
                    <ng-container
                        *ngFor="let locationGroup of bookingService.locationList$ | async | locationGroupSchedule :'city':this.bookingService.activeLocationByService$.value">
                        <ng-container *ngFor="let location of locationGroup.value">
                            @if(location.id == selectedLocation){
                            <option [value]="location.id" selected="selected" *ngIf="location.enabled">
                                {{ locationGroup.key }} - {{ location.name }} - {{ location.address.line1 }}
                            </option>
                            }@else {
                            <option [value]="location.id" *ngIf="location.enabled">
                                {{ locationGroup.key }} - {{ location.name }} - {{ location.address.line1 }}
                            </option>
                            }
                        </ng-container>
                    </ng-container>
                </select> -->

                }
                <!-- <span class="cost" *ngIf="router.url !== '/booking/schedule' ">
                    <button *ngIf="!showEditLocationLoader" class="edit-location" (click)="editLocation()" style="display: none;"><span
                            class="accessibility">Edit Location</span></button>
                    <div *ngIf="showEditLocationLoader" class="loader" style="width: 20px; text-align: center;"><img
                            src="assets/Iphone-spinner.gif"></div>
                </span> -->
            </span>
            <span class="cost change-location" *ngIf="router.url == '/booking/services' || router.url == '/booking/add-ons' ">
                <button class="edit-location" routerLink="/booking/location" ><span
                        class="accessibility">Edit Location</span></button>
                
            </span>
        </li>}@else if(router.url !== '/booking/review'){
        <span class="pill location">Select Location</span>
            <span class="location-address">
                <!-- <select class="location-change" (change)="bookingService.changeLocation($event)">
                    <ng-container
                        *ngFor="let locationGroup of bookingService.locationList$ | async | locationGroupSchedule :'city':this.bookingService.activeLocationByService$.value">
                        <ng-container *ngFor="let location of locationGroup.value">
                            @if(location.id == selectedLocation){
                            <option [value]="location.id" selected="selected" *ngIf="location.enabled">
                                {{ locationGroup.key }} - {{ location.name }}- {{ location.address.line1 }}
                            </option>
                            }@else {
                            <option [value]="location.id" *ngIf="location.enabled">
                                {{ locationGroup.key }} - {{ location.name }} - {{ location.address.line1 }}
                            </option>
                            }
                        </ng-container>
                    </ng-container>
                </select> -->
            </span>
        }
    </ul>

    <div class="flex-container cart-tax cart-total" *ngIf="cart?.summary?.taxAmount && router.url == '/booking/review'">
        <span class="eyebrow small">Tax</span>
        <p class="small cart-price">{{sharedService.formatPrice(this.cart?.summary?.taxAmount) | currency:'USD':'symbol'}}</p>
    </div>
    <div class="flex-container cart-subtotal cart-total" *ngIf="cart?.offers?.length && this.cart?.summary?.discountAmount">
        <span class="eyebrow small">Subtotal</span>
        <p class="small cart-price">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</p>
    </div>
    <div class="flex-container cart-discount cart-total" *ngIf="cart?.offers?.length && this.cart?.summary?.discountAmount">
        <span class="eyebrow small">{{cart.offers[0]?.name}}</span>
        <p class="small cart-price">-{{sharedService.formatPrice(this.cart?.summary?.discountAmount) | currency:'USD':'symbol'}}
        </p>
    </div>
    <div class="flex-container cart-total" *ngIf="cart?.selectedItems?.length">
          <div *ngIf="(cart?.summary?.discountAmount || router.url == '/booking/review')else subtotalText">
            <span class="eyebrow small">Total</span>
          </div>
          <ng-template #subtotalText>
            <span class="eyebrow small">Subtotal</span>
          </ng-template>
          
                  <!-- Total without location <p class="small">{{sharedService.formatPrice(getServicePriceWithoutLocationTotal()) | currency:'USD':'symbol'}}</p> -->
        <p class="small cart-price" *ngIf="router.url != '/booking/review'">{{sharedService.formatPrice((cart?.summary?.discountAmount)?this.cart?.summary?.total:this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</p>
        <p class="small cart-price" *ngIf="router.url == '/booking/review'">{{sharedService.formatPrice(this.cart?.summary?.total) | currency:'USD':'symbol'}}</p>
    </div>

    <ul class="xx-small cart-details" *ngIf="cart?.startTime && router.url == '/booking/review'">
        <li *ngIf="cart?.startTime && router.url == '/booking/review'" class="time">{{cart?.startTime | date:'EEEE, MMMM
            d @ h:mm a'}}</li>
        <!-- <li *ngIf="getSelectedStaffVariant() && router.url == '/booking/review'" class="person">Esthetician: {{getSelectedStaffVariant()}}</li> -->
        <li class="location">{{cart?.location?.address?.city}} - {{cart?.location?.name}}</li>
    </ul>
</div>
<!-- <a class="btn inactive" href="#">Add to Continue</a> -->
<button class="btn black view-cart mobile-only" (click)="this.bookingService.toggleMobileCartBeboreReview()" 
*ngIf="(bookingService.mobileCartView | async) && this.bookingService.bookableTimeId">Continue to payment</button>

<!-- <button class="btn black view-cart mobile-only">View Cart<span class="item-count">{{getTotalAddedServiceCount}}</span></button> -->