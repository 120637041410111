import { Injectable } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Notification, NotificationService } from '../notification.service';
import { ConfirmationAlertComponent } from './confirmation-alert/confirmation-alert.component';
import { LocationConfirmationAlertComponent } from './location-confirmation-alert/location-confirmation-alert.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  confirmationAlertModalRef!: MdbModalRef<ConfirmationAlertComponent> | null;
  
  modalConfig: any = {
    animation: true,
    backdrop: true,
    containerClass: "right",
    data: {},
    ignoreBackdropClick: false,
    keyboard: true,
    modalClass: "modal-top-right",
  };

  locatioConfirmationAlertModalRef!: MdbModalRef<LocationConfirmationAlertComponent> | null;


  locationModalConfig: any = {
    animation: true,
    backdrop: true,
    containerClass: "right",
    data: {},
    ignoreBackdropClick: false,
    keyboard: true,
    modalClass: "modal-top-right",
  };



  keep_my_cart_and_continue:boolean=false;

  constructor( private notificationService:NotificationService, private modalService: MdbModalService ) { }

  getLocalStorageItem(key:string){
    return localStorage.getItem(key);
  }

  setLocalStorageItem(key:string, value:string){
    localStorage.setItem(key, value);
  }

  removeLocalStorageItem(key:string){
    localStorage.removeItem(key);
  }

  showNotification(title:string, message:string){
    const notification:Notification = {
      'title': title,
      'message': message
    }
    this.notificationService.$notification.next(notification);
  }

  formatPrice(price:number){
    return price/100;
  }

  getServiceCategoryName(service:any, categories:any){
    let categoryName = '';
    if(categories.length){
      categories.map((category:any)=>{
        category.availableItems.map((availableItem:any)=>{
          if(availableItem.id == service.item.id){
            categoryName = category.name;
          }
        })
      })
    }
    if(categoryName.toLowerCase().includes('facial')){
      return categoryName.replace("Facials ", "");
    }else{
      return '';
    }
  }

  findServiceIdByName(serviceName:string, cart:any){
    let foundService:any;
    cart.availableCategories.map((cat:any)=>{
      cat.availableItems.map((item:any)=>{
        item.name.trim().replaceAll('/', '').replaceAll(' ', '').toLowerCase() == serviceName.trim().replaceAll(' ', '').toLowerCase() ? foundService = item : null;
      });
    })
    return foundService;
  }

  openConfirmationAlert(message:string){
    this.modalConfig.data.message = message;
    return new Promise((resolve, reject)=>{
      this.confirmationAlertModalRef = this.modalService.open(
        ConfirmationAlertComponent,
        this.modalConfig
      );
      this.confirmationAlertModalRef.onClose.subscribe((data:any)=>{
        resolve(data);
      });
    });
  }

  async locationOpenConfirmationAlert(message: string): Promise<any> {
    this.locationModalConfig.data.message = message;
    return new Promise((resolve, reject) => {
      this.locatioConfirmationAlertModalRef = this.modalService.open(
        LocationConfirmationAlertComponent,
        this.locationModalConfig
      );

      this.locatioConfirmationAlertModalRef?.onClose.subscribe((data: any) => {
        resolve(data);
      }, reject);
    });
  }

  removeLastTimezone(str: string): string {
    if (str.slice(-6).match(/[+-]\d{2}:\d{2}$/)) {
      return str.slice(0, -6); // remove the last 6 characters (-04:00)
    }
    return str;
  }

  getSkinType(description:string): string | null {
    const startIndex = description.indexOf('Skin type:');
    if (startIndex !== -1) {
      return description.substring(startIndex + 11).trim();
    }
    return null;
  }

  getServiceOrder(desc:string):string | null {
    const startIndex = desc.indexOf('Order');
    if (startIndex !== -1) {
      return desc.substring(startIndex + 6, startIndex + 8).trim();
    }
    return null;
  }

  getDescriptionOnly(desc:string):string{
    return desc.replace('Skin type: ' + this.getSkinType(desc), '').replace('Order:' + this.getServiceOrder(desc), '');
  }

  getLast10digits(phone: string): string {
    if (phone.length <= 10) {
      return phone; // return the full string if it's less than or equal to 10 characters
    } else {
      return phone.slice(-10); // return the last 10 characters of the string
    }
  }

  decimalRound(num:number){
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
  getGiftCardName(): string {
    const giftCardDetail = localStorage.getItem('giftCardDetail');
    if (giftCardDetail) {
        const giftCardDetails = JSON.parse(giftCardDetail);
        // Replace underscores with spaces or return an empty string if giftCardName doesn't exist
        return giftCardDetails?.giftCardName?.replace(/_/g, ' ') || '';
    }
    return '';
}

  getPackageName(){
    const packageDetail = localStorage.getItem('packageDetail');
      if(packageDetail){
        var packageDetails = JSON.parse(packageDetail);
      }
      return packageDetails.packageName;
  }
  getPackagepackageDescription() {
    const packageDetail = localStorage.getItem('packageDetail');
    //console.log('Stored giftCardDetail:', packageDetail); // Debugging
  
    if (packageDetail) {
      try {
        const packageDetails = JSON.parse(packageDetail);
        //console.log('Parsed giftCardDetails:', packageDetails); // Debugging
        return packageDetails.packageDescription || []; // Ensure fallback to empty array if undefined
      } catch (error) {
        console.error('Error parsing giftCardDetail:', error);
      }
    }
    return []; // Fallback if giftCardDetail is not present
  }

  removeCountryCode(phone:string){
    let countryCode = phone.substring(0,2);
    if(countryCode == '+1'){
      return phone.substring(2);
    }else{
      return phone;
    }
  }
 

}
