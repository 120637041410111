import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationGroup'
})
export class LocationGroupPipe implements PipeTransform {

  transform(collection: any[], property: string): any[] {
    const groupedCollection = Object.entries(collection.reduce((previous, current)=> {
      const city = current.node.address.city;
      if (previous[city]) {
        previous[city].push(current.node);
      } else {
        previous[city] = [current.node];
      }

        return previous;
    }, {})).map(([key, value]) => ({ key, value }));

    if(groupedCollection.length >= 2) {
      groupedCollection[groupedCollection.length] = groupedCollection[0];
      groupedCollection.shift();
      let temp = groupedCollection[2];          
      groupedCollection.splice(2, 1);            
      groupedCollection.unshift(temp);           
      console.log(groupedCollection);  
      groupedCollection.forEach(group => {
        (group.value as any[]).sort((a, b) => a.name.localeCompare(b.name));
    });
          
      
    }
 
  
    return groupedCollection;
  }
}
