import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from 'src/app/shared-component/shared.service';

@Component({
  selector: 'app-services-addons-tabs',
  templateUrl: './services-addons-tabs.component.html',
  styleUrl: './services-addons-tabs.component.scss'
})
export class ServicesAddonsTabsComponent {
  tab:number = 0;
  @Input() isAddonEnable:boolean = false;
  @Input() reset:any;
  @Input() cart:any;
  @Input() client:any;
  @Output() changeTabEvent = new EventEmitter<string>();

  categories:Array<string> = [];

  categoryOrder = [
    {
      category: 'EXFOLIATION ENHANCEMENTS',
      order: 1,
    },
    {
      category: 'CUSTOM-FOR-ALL ENHANCEMENTS',
      order: 2,
    },
    {
      category: 'YOUTHFUL ENHANCEMENTS',
      order: 3,
    },
    {
      category: 'CHEMICAL PEELS',
      order: 4,
    },
    {
      category: 'EYEBROWS',
      order: 5,
    }
  ]

  constructor(private sharedService:SharedService){ }

  changeTab(tab:any){
      this.tab = tab;
    this.changeTabEvent.emit(tab);
  }

  get categorizeAddons() {
    if(this.cart && this.cart.selectedItems){
      let addonsList:any = this.cart.selectedItems.filter((item:any)=>{
        if(this.client == 'me'){
          return item.guestId == null && !item.item.optionGroups.length
        }else{
          return item.guestId == this.client.id && !item.item.optionGroups.length
        }
      })[0].addons;

      let categories = Array.from(new Set(addonsList.map((option:any) => option.optionGroups[0].name)));
      categories.sort((a:any, b:any) => {
          const nameA = a.toUpperCase();
          const nameB = b.toUpperCase();

          const orderA = this.categoryOrder.find(cat=> cat.category.toUpperCase() == nameA)?.order;
          const orderB = this.categoryOrder.find(cat=> cat.category.toUpperCase() == nameB)?.order;

          return Number(orderA) - Number(orderB);
      })

      return categories;

    }else{
      return []
    }
  }

}
