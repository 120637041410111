import { Pipe, PipeTransform } from '@angular/core';
import { BookingService } from '../booking.service';
import { SharedService } from 'src/app/shared-component/shared.service';

@Pipe({
  name: 'filterAddons'
})
export class FilterAddonPipe implements PipeTransform {

  constructor(private bookingService:BookingService, private sharedService:SharedService){}

  transform(addons: Array<any>, filter:number, client:any): Array<any> {

    const catAddons = this.categorizeAddons(addons);

    if(catAddons.length){
      return catAddons[filter].addons;
    }else{
      return [];
    }
    //return addons;

    // return addons;

    // if(categories?.length){
    //   let service = categories.filter((availableItem:any)=> {
    //     return availableItem.name.toLowerCase().includes(filter.toLowerCase());
    //   });
    //   if(service.length){
    //     service.map((availableService:any)=>{
    //       availableService.availableItems.map((item:any)=>{
    //         item.selected = false;
    //       })
    //     });
    //   }
    //   service = this.isServiceAdded(service, client);
    //   return service;
    // }else{
    //   return categories[0];
    // }
  }

  // isServiceAdded(services:Array<any>, client:any){
  //   let selectedItems = this.bookingService.clientCart$.value.selectedItems;
  //   selectedItems = selectedItems.filter((selectedItem:any)=> {
  //     if(client!= 'me'){
  //       return selectedItem.guestId == client.id
  //     }else{
  //       return selectedItem.guestId == null;
  //     }
  //   });
  //   services.map((availableService:any)=>{
  //     availableService.availableItems.map((item:any)=>{
  //       selectedItems.map((selectedService:any)=>{
  //         item.id == selectedService.item.id ? item.selected = true : null;
  //       })
  //     })
  //   });
  //   return services;
  // }

  categorizeAddons(addonsList: any) {
    // Check if addonsList is an array and has elements
    if (!Array.isArray(addonsList) || addonsList.length === 0) {
      return [];
    }else{
      // Extract unique category names from addonsList
      const categories = Array.from(new Set(
        addonsList.map((option: any) => option.optionGroups?.[0]?.name).filter(Boolean)
      ));
  
      // Categorize addons and sort them
      const result = categories.map((category: any) => {
        // Filter addons that belong to the current category
        const addons = addonsList.filter((option: any) => option.optionGroups?.[0]?.name === category);
    
        // Sort addons within each category
        addons.sort((a: any, b: any) => {
          const orderA = Number(this.sharedService.getServiceOrder(a.description)) || 0;
          const orderB = Number(this.sharedService.getServiceOrder(b.description)) || 0;
    
          // Log if orders are not valid numbers
          if (isNaN(orderA) || isNaN(orderB)) {
            console.error('Service order is not a valid number', { orderA, orderB, a, b });
          }
    
          return orderA - orderB;
        });
    
        return { category, addons };
      }); 
      return result;
    }
  
    // Optional: Sort categories if needed (uncomment if sorting categories is required)
    // result.sort((a: any, b: any) => {
    //   const nameA = a.category.toUpperCase();
    //   const nameB = b.category.toUpperCase();
  
    //   const orderA = this.categoryOrder.find(cat => cat.category.toUpperCase() === nameA)?.order;
    //   const orderB = this.categoryOrder.find(cat => cat.category.toUpperCase() === nameB)?.order;
  
    //   // Log if orders are not found
    //   if (orderA === undefined || orderB === undefined) {
    //     console.error('Category order not found', { nameA, nameB, orderA, orderB });
    //   }
  
    //   return Number(orderA) - Number(orderB);
    // });
  }
  
  
  

}
