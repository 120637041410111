import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedService } from 'src/app/shared-component/shared.service';
import { GiftcardService } from '../giftcard.service';
import { firstValueFrom } from 'rxjs';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent {

  paymentForm!:FormGroup;
  userInfoForm!:FormGroup;
  cart:any;
  promoCode:any;
  toggleMobileCart:boolean = false;
  user:any;
  isCustomAmountSelected:any;

  constructor(private trackingService:TrackingService, private authService:AuthService, private router:Router, private formBuilder:FormBuilder, public sharedService:SharedService, public giftCardService:GiftcardService){
    giftCardService.clientCart$.subscribe((cart:any)=>{
      if(cart){
        this.cart = cart;
        this.promoCode = cart?.offers.length ? cart?.offers[0].code : null;
        if(this.cart.selectedItems.length && this.cart.selectedItems[0].item.id == 'GIFT_CARD'){
          this.isCustomAmountSelected = true;
        }else{
          let recipient:any = localStorage.getItem('giftcardRecepient');
          if(recipient){
            recipient = JSON.parse(recipient);
            recipient.messageFromSender = recipient.message;
            recipient.deliveryDate = recipient.sendDate;
            this.cart.selectedItems[0].emailFulfillment = recipient;
          }
        }
      }
    })
    setTimeout(() => {
      this.trackingService.initiateCheckout();
    }, 500);
    !this.cart ? giftCardService.getCartDetail() : null;
  }

  ngOnInit(){
    this._buildForm();
    this.authService.$AuthUser.subscribe((user:any)=>{
      if(user){
        this._patchAdditionalInfoForm(user);
        this.user = user;
      }
    });
  }

  _patchAdditionalInfoForm(user:any){
    this.userInfoForm.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobilePhone: this.sharedService.getLast10digits(user.mobilePhone) || this.sharedService.getLast10digits(user.phoneNumber),
    });
    this.userInfoForm.controls.email.disable();
  }

  _buildForm(){
    // PaymentForm
    this.paymentForm = this.formBuilder.group({
      name: ['', Validators.required],
      number: ['', Validators.compose([Validators.required, Validators.maxLength(19), Validators.minLength(9)])],
      cvv: ['', Validators.compose([Validators.required, Validators.maxLength(4), Validators.minLength(3), this.cvvValidator])],
      expiry: ['', Validators.compose([Validators.required, Validators.maxLength(7), Validators.minLength(7), this.expiryValidator])],
      postal_code: ['', Validators.compose([Validators.required, Validators.maxLength(5), Validators.minLength(5)])],
    });

    // userInfoForm
    this.userInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      mobilePhone: ['', Validators.compose([Validators.required, Validators.maxLength(10), Validators.minLength(10)])],
    });
  }

  cvvValidator(control: FormControl): { [key: string]: any } | null {
    const valid = /^\d{3,4}$/.test(control.value);
    return valid ? null : { invalidCVV: true };
  }

  expiryValidator(control: FormControl): { [key: string]: any } | null {
    if(control.value){
      const splitVal = control.value.split('/')
      const month = Number(splitVal[0]);
      const year = Number(splitVal[1]);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      const valid = year >= currentYear && splitVal[0].length && ((currentYear == year && month >= currentMonth) || (year > currentYear && month <= 12));
      return valid ? null : { invalidExpiry: true };
    }else{
      return { invalidExpiry: true };
    }
  }

  maskExpiryDate(expiryDate: string): string {
    // Extract the month and year from the expiry date string
    const [month, year] = expiryDate.split('/');
  
    // Replace the year with asterisks
    const maskedYear = year;
  
    // Concatenate the masked month and year with a forward slash
    const maskedExpiryDate = `${month}/${maskedYear}`;
  
    return maskedExpiryDate;
  }

  onExpiryDateInput(): void {
    // Get the raw input value from the input field
    const rawValue = this.paymentForm.value.expiry.replace(/\D/g, '');

    // Extract the month and year components from the raw input value
    const month = rawValue.substr(0, 2);
    const year = rawValue.substr(2, 4);

    // Mask the expiry date
    const maskedExpiryDate = this.maskExpiryDate(`${month}/${year}`);

    // Update the input field value with the masked expiry date
    this.paymentForm.patchValue({
      expiry:maskedExpiryDate
    });
  }

  async applyPromoCode(){
    if(this.promoCode && this.promoCode != ''){
      // if((this.promoCode.toLowerCase() == 'motherday24' && !this.giftCardService.isCustomAmountSelected && this.giftCardService?.selectedGiftCard?.value?.price != 500) || (this.giftCardService.isCustomAmountSelected && this.promoCode.toLowerCase() == 'motherday24')){
      //   const title = 'Invalid promo code';
      //   const message = 'Please enter valid promo code';
      //   return this.sharedService.showNotification(title, message);
      // }
      const resaddCartOffer: any = await firstValueFrom(this.giftCardService.addCartOffer(this.promoCode));
      if(!resaddCartOffer.errors && resaddCartOffer?.data?.addCartOffer?.offer?.applied){
        const title = 'Promo Code';
        const message = 'applied successfully';
        this.sharedService.showNotification(title, message);
        localStorage.setItem("promoCode", this.promoCode);
        this.giftCardService.getCartDetail();
      }else{
        const title = 'Invalid promo code';
        const message = 'Please enter valid promo code';
        this.sharedService.showNotification(title, message);
        !resaddCartOffer?.data?.addCartOffer?.offer?.applied ? await firstValueFrom(this.giftCardService.removeCartOffer(resaddCartOffer?.data?.addCartOffer?.offer?.id)) : null;
      }
    }
  }

  async removePromoCode(){
    let offerId = this.cart?.offers[0].id;
    if(offerId){
      const resRemoveCartOffer:any = await firstValueFrom(this.giftCardService.removeCartOffer(offerId));
      if(!resRemoveCartOffer.errors){
        const title = 'Promo Code';
        const message = 'removed successfully';
        this.sharedService.showNotification(title, message);
        this.giftCardService.getCartDetail();
        this.sharedService.removeLocalStorageItem('promoCode');
        this.promoCode = '';
      }
    }
  }

  async updateSenderNameinEmailFulfilment(){
    const payload = {
      messageFromSender: this.cart?.selectedItems[0]?.emailFulfillment?.messageFromSender,
      senderName: this.userInfoForm.value.firstName + ' ' + this.userInfoForm.value.lastName,
      senderEmail: this.userInfoForm.value.email,
      recipientEmail: this.cart?.selectedItems[0]?.emailFulfillment?.recipientEmail,
      recipientName: this.cart?.selectedItems[0]?.emailFulfillment?.recipientName,
      deliveryDate: this.cart?.selectedItems[0]?.emailFulfillment?.deliveryDate
    }
    //console.log("isCustomAmountSelected",this.isCustomAmountSelected);
    if(this.isCustomAmountSelected){
      const resEmailfulfilment:any = await firstValueFrom(this.giftCardService.updateEmailfulfilment(payload));
    }else{
      let recipient:any = localStorage.getItem('giftcardRecepient');
      recipient = JSON.parse(recipient);
      localStorage.setItem('giftcardRecepient', JSON.stringify({...recipient, ...payload}));
    }

  }

  async updateUserInfo(){
    if(this.userInfoForm.valid){
      this.updateSenderNameinEmailFulfilment();
      const client = this.userInfoForm.value;
      client.email = this.userInfoForm.controls['email'].value;
      const resUpdateClientCartInfo:any = await firstValueFrom(this.giftCardService.updateClientCartInfo(client));
      if(!resUpdateClientCartInfo.errors){
        this.cart.clientInformation = resUpdateClientCartInfo?.data?.updateCart?.cart?.clientInformation;
      }
      return resUpdateClientCartInfo
    }else{
      this.userInfoForm.markAllAsTouched();
      const title = 'Incorrect user information';
      const message = 'Please fill the correct sender information';
      return this.sharedService.showNotification(title, message);
    }
  }

  selectPaymentMethod(card:any){}

  async updatePaymentMethod(){
    if(this.paymentForm.valid){

      const resTokenizeCard: any = await firstValueFrom(this.giftCardService.tokenizeCard(this.paymentForm.value));

      if(resTokenizeCard.token){
        const resAddCartPaymentMethod: any = await firstValueFrom(this.giftCardService.addCartPaymentMethod(resTokenizeCard.token)); 
        if(!resAddCartPaymentMethod.errors){
          this.paymentForm.reset();
        }else{
          return this.sharedService.showNotification("Error", resAddCartPaymentMethod.errors[0].message);
        }
        return resAddCartPaymentMethod;
      }else{
        let message = 'Please add correct card';
        if(resTokenizeCard?.error?.errors?.number_last4?.length){
          message = 'Please check your card number'
          this.paymentForm.controls['number'].setErrors({invalidCard: true});
        }
        const title = 'Incorrect payment information';
        return this.sharedService.showNotification(title, message);
      }
    }else{
      this.paymentForm.markAllAsTouched();
      // reject(false);
      const title = 'Incorrect payment information';
      const message = 'Please add correct card';
      return this.sharedService.showNotification(title, message);
    }
  }

  async getCartDetail(){
    const resCartDetail: any = await firstValueFrom(this.giftCardService.cartDetail());
    if(!resCartDetail.errors){
      this.giftCardService.clientCart$.next(resCartDetail.data.cart);
    }else{
      this.sharedService.showNotification("Error", resCartDetail.errors[0].message);
    }
  }

  async checkout(){
    if(this.cart.clientInformation){
      const resCheckoutCart: any = await firstValueFrom(this.giftCardService.checkoutCart());
      if(!resCheckoutCart.errors){
        const title = 'Glowing Thanks for Your Gift';
        const message = 'You have successfully purchased a Gift Card!';
        this.sharedService.showNotification(title, message);
      }
      return resCheckoutCart;
    }else{
      const title = 'Sender information is required';
      const message = 'please save the sender information';
      return this.sharedService.showNotification(title, message);
    }
  }

  async easyCheckout(){
    // Update User Info
    const resupdateUserInfo = await this.updateUserInfo();

    if(!resupdateUserInfo.errors){
      // Update Payment Method
      const resupdatePaymentMethod = await this.updatePaymentMethod();

      if(!resupdatePaymentMethod.errors){
        // Checkout
        const resCheckout = await this.checkout();

        if(!resCheckout.errors){
          // Get Cart Detail
          await this.getCartDetail();

          localStorage.removeItem('recipientType');
          localStorage.removeItem('giftcardCart');
          this.router.navigateByUrl('/giftcards/thanks');  
        }
      }
    }

  }

}
