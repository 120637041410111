<app-header mainTitle="Book an Appointment"></app-header>

<section class="review-flow">
	<form class="container grid">
		
		<!--Breadcrumbs-->
        <app-breadcrumbs class="breadcrumbs-container"></app-breadcrumbs>

		<app-mobile-menu class="user-head mobile-head"></app-mobile-menu>

		<div class="content-container">
			
			<!--Credit Card-->
			
			<!-- <h2 class="heading-6" *ngIf="availablePaymentMethods.length">Payment Details</h2> -->
			<!-- <p *ngIf="!(authService.$AuthUser | async)" class="xx-small">Have an account? <a class="text-link" routerLink="/auth">Login or create an account</a></p> -->

			<!-- <label class="pill" for="card-detail-options" *ngIf="availablePaymentMethods.length">Card Details</label> -->
			
			<!-- <div class="flex-container credit-card-options" *ngIf="availablePaymentMethods.length">
				<ul id="card-detail-options" class="x-small" *ngFor="let card of availablePaymentMethods">
					<li *ngIf="card.id && card.cardLast4" [ngClass]="cart?.selectedItems[0]?.selectedPaymentMethod?.id == card?.id ? 'active' : 'inactive' " class="visa" (click)="selectPaymentMethod(card)">
						<strong>{{card?.cardBrand}} •••• {{card?.cardLast4}} <span>Expires {{card?.cardExpMonth}}/{{card?.cardExpYear}}</span></strong>
						<span *ngIf="card?.cardIsDefault" class="pill fill small">Default</span>
					</li>
				</ul>
				
				
			</div> -->
			<!-- <button class="text-link large add-new-card" (click)="togglePaymentMethodForm = !togglePaymentMethodForm">{{togglePaymentMethodForm ? '-' : '+'}} Add a new card</button>
			<form [formGroup]="paymentForm" *ngIf="togglePaymentMethodForm">

				<ul class="grid credit-card-details">
					<li>
						<label class="pill" for="name">Name On The Card*</label>
						<input formControlName="name" type="text" id="name" placeholder="Name"/>
						<div class="required" *ngIf="paymentForm.controls.name.invalid && (paymentForm.controls.name.dirty || paymentForm.controls.name.touched)">
                            <div *ngIf="paymentForm.controls.name.errors?.required">Name is required</div>
                        </div>
					</li>
					<li>
						<label class="pill" for="card-details">Card Details*</label>
						<input maxLength="16" minLength="15" formControlName="number" type="text" id="card-details" placeholder="Card Number"/>
						<div class="required" *ngIf="paymentForm.controls.number.invalid && (paymentForm.controls.number.dirty || paymentForm.controls.number.touched)">
                            <div *ngIf="paymentForm.controls.number.errors?.required">Card number is required</div>
                        </div>
					</li>

					<li class="mobile-only">
						<p class="xx-small info">Your credit card is only used to hold the reservation and won't be charged unless you cancel late or don't show up.</p>
					</li>
					
					<li>
						<label class="pill" for="card-exp">Expiration Date*</label>
						<input  maxLength="7" minLength="7" (input)="onExpiryDateInput()" formControlName="expiry" type="text" id="card-exp" placeholder="MM/YYYY"/>
						<div class="required" *ngIf="paymentForm.controls.expiry.invalid && (paymentForm.controls.expiry.dirty || paymentForm.controls.expiry.touched)">
                            <div *ngIf="paymentForm.controls.expiry.errors?.required">Expiration date is required</div>
                            <div *ngIf="!paymentForm.controls.expiry.errors?.required && paymentForm.controls.expiry.errors?.invalidExpiry">Expiration date is invalid</div>
                        </div>
					</li>
					
					<li>
						<label class="pill" for="card-cvv">CVV*</label>
						<input maxLength="4" minLength="3" formControlName="cvv" type="tel" id="card-cvv" placeholder="***"/>
						<div class="required" *ngIf="paymentForm.controls.cvv.invalid && (paymentForm.controls.cvv.dirty || paymentForm.controls.cvv.touched)">
                            <div *ngIf="paymentForm.controls.cvv.errors?.required">CVV is required</div>
                        </div>
					</li>

					<li>
						<label class="pill" for="postal-code">Zip Code*</label>
						<input maxLength="5" minLength="5" formControlName="postal_code" type="text" id="postal-code" placeholder="Zip Code"/>
						<div class="required" *ngIf="paymentForm.controls.postal_code.invalid && (paymentForm.controls.postal_code.dirty || paymentForm.controls.postal_code.touched)">
                            <div *ngIf="paymentForm.controls.postal_code.errors?.required">Zip code is required</div>
                        </div>
					</li>
				</ul>
				<p class="xx-small info">Your credit card is only used to hold the reservation and won't be charged unless you cancel late or don't show up.</p>
			</form> -->
			
			<!--Additional Fields-->
			<h2 class="heading-6">Your Additional Information</h2>

			<form [formGroup]="userInfoForm">
				<ul class="grid additional-info">
					<li>
						<label class="pill" for="email">Email Address*</label>
						<input (blur)="onEmailKeyup(userInfoForm.controls.email.value)"  formControlName="email" type="email" id="email" placeholder="email@address.com"/>
						<div class="required" *ngIf="userInfoForm.controls.email.invalid && userInfoForm.controls.email.touched">
                            <div *ngIf="userInfoForm.controls.email.errors?.required">Email Address is required</div>
                        </div>
					</li>
					<li>
						<label class="pill" for="first-name">First Name*</label>
						<input formControlName="firstName" type="text" id="first-name" placeholder="First Name"/>
						<div class="required" *ngIf="userInfoForm.controls.firstName.invalid && userInfoForm.controls.firstName.touched">
                            <div *ngIf="userInfoForm.controls.firstName.errors?.required">First name is required</div>
                        </div>
					</li>
					
					<li>
						<label class="pill" for="last-name">Last Name*</label>
						<input formControlName="lastName" type="text" id="last-name" placeholder="Last Name"/>
						<div class="required" *ngIf="userInfoForm.controls.lastName.invalid && userInfoForm.controls.lastName.touched">
                            <div *ngIf="userInfoForm.controls.lastName.errors?.required">Last name is required</div>
                        </div>
					</li>
					
					<li>
						<label class="pill" for="phone">Phone Number*</label>
						<input type="tel" mask='(000) 000-0000' formControlName="mobilePhone" id="phone" placeholder="(555) 555-5555"/>
						<div class="required" *ngIf="userInfoForm.controls.mobilePhone.invalid && userInfoForm.controls.mobilePhone.touched">
                            <div *ngIf="userInfoForm.controls.mobilePhone.errors?.required">Phone Number is required</div>
                        </div>
					</li>
					
					
					
					<li>
						<label class="pill" for="notes">Notes</label>
						<p class="xx-small">Preferences? Celebrations? Secrets you want to share? Let us know.</p>
						<input formControlName="note" type="text" id="notes" placeholder="Write any notes for us"/>
					</li>
				</ul>
			
				
				<div [formGroup]="couponForm" class="mobile-only flex-container promo-code">
					<label class="pill small" for="promo-code">Promo Code</label>
					<input formControlName="promoCode" type="text" id="promo-code" placeholder="Enter Code"/>
					<button *ngIf="!cart?.offers?.length" (click)="applyPromoCode()" type="submit" class="text-link large">Apply</button>
					<button *ngIf="cart?.offers?.length" (click)="removePromoCode()" type="submit" class="text-link large">Remove</button>
				</div>
			</form>	

			<h2 class="heading-6">Credit Card Payment Details</h2>

			<label class="pill" for="card-detail-options" *ngIf="availablePaymentMethods.length">Card Details</label>
			
			<div class="flex-container credit-card-options" *ngIf="availablePaymentMethods.length">
				<ul id="card-detail-options" class="x-small" *ngFor="let card of getUniquePaymentMethods(availablePaymentMethods)">
				  <li *ngIf="card.id && card.cardLast4" [ngClass]="cart?.selectedItems[0]?.selectedPaymentMethod?.id == card?.id ? 'active' : 'inactive' " class="visa" (click)="selectPaymentMethod(card)">
					<strong>{{card?.cardBrand}} •••• {{card?.cardLast4}} <span>Expires {{card?.cardExpMonth}}/{{card?.cardExpYear}}</span></strong>
					<span *ngIf="card?.cardIsDefault" class="pill fill small">Default</span>
				  </li>
				</ul>
			  </div>
			  

			<form [formGroup]="paymentForm">

				<ul class="grid credit-card-details">
					<li>
						<label class="pill" for="name">Name On The Card*</label>
						<input formControlName="name" type="text" id="name" placeholder="Name"/>
						<div class="required" *ngIf="paymentForm.controls.name.invalid && paymentForm.controls.name.touched">
                            <div *ngIf="paymentForm.controls.name.errors?.required">Name is required</div>
                        </div>
					</li>
					<li>
						<label class="pill" for="card-details">Card Details*</label>
						<input mask='0000 0000 0000 0000 000' [validation]="false" formControlName="number" type="text" id="card-details" placeholder="Card Number"/>
						<div class="required" *ngIf="paymentForm.controls.number.invalid && paymentForm.controls.number.touched">
                            <div *ngIf="paymentForm.controls.number.errors?.required">Card number is required</div>
                            <div *ngIf="paymentForm.controls.number.errors?.minlength || paymentForm.controls.number.errors?.maxlength || paymentForm.controls.number.errors?.invalidCard">Invalid card</div>
                        </div>
					</li>

					<li class="mobile-only">
						<p class="xx-small info">Your credit card is only used to hold the reservation and won't be charged unless you cancel late or don't show up.</p>
					</li>
					
					<li>
						<label class="pill" for="card-exp">Expiration Date*</label>
						<input  maxLength="7" minLength="7" (input)="onExpiryDateInput()" formControlName="expiry" type="text" id="card-exp" placeholder="MM/YYYY"/>
						<div class="required" *ngIf="paymentForm.controls.expiry.invalid && paymentForm.controls.expiry.touched">
                            <div *ngIf="paymentForm.controls.expiry.errors?.required">Expiration date is required</div>
                            <div *ngIf="!paymentForm.controls.expiry.errors?.required && paymentForm.controls.expiry.errors?.invalidExpiry">Expiration date is invalid. (MM/YYYY)</div>
                        </div>
					</li>
					
					<li *ngIf="paymentForm.controls.number.valid && paymentForm.controls.expiry.valid">
						<label class="pill" for="card-cvv">CVV*</label>
						<input maxLength="4" minLength="3" formControlName="cvv" type="tel" id="card-cvv" placeholder="***"/>
						<div class="required" *ngIf="paymentForm.controls.cvv.invalid && paymentForm.controls.cvv.touched">
                            <div *ngIf="paymentForm.controls.cvv.errors?.required">CVV is required</div>
                            <div *ngIf="!paymentForm.controls.cvv.errors?.required && paymentForm.controls.cvv.errors?.invalidCVV">Invalid CVV</div>
                        </div>
					</li>

					<li *ngIf="paymentForm.controls.number.valid && paymentForm.controls.expiry.valid">
						<label class="pill" for="postal-code">Zip Code*</label>
						<input maxLength="5" minLength="5" formControlName="postal_code" type="text" id="postal-code" placeholder="Zip Code"/>
						<div class="required" *ngIf="paymentForm.controls.postal_code.invalid && paymentForm.controls.postal_code.touched">
                            <div *ngIf="paymentForm.controls.postal_code.errors?.required">Zip code is required</div>
							<div *ngIf="paymentForm.controls.postal_code.errors?.minlength || paymentForm.controls.postal_code.errors?.maxlength">Zip code must be of 5 digits.</div>
                        </div>
					</li>
				</ul>
				<!-- <button type="submit" class="btn black cc-btn">Apply</button> -->
				<p class="xx-small info">Your credit card is only used to hold the reservation and won't be charged unless you cancel late or don't show up.</p>
				<h3 style="font-weight: bold; margin: 30px 0 2px; font-size: 0.875rem/1.4285714286 var(--body-font);">Disclaimer</h3>
				<p class="xx-small">
					By booking this appointment, you confirm that you have read and understand our cancellation and lateness policy. Any no-show or late cancellation within 24 hours of your appointment is subject to a charge of 50% of the service price. More information on our <a class="text-link" href="https://silvermirror.com/about-us/faq-general/" target="_blank">Cancellation Policy</a>, please visit our <a class="text-link" href="https://silvermirror.com/about-us/faq-general/" target="_blank">FAQ</a>.
				</p>
			</form>
			
			<div class="continue-btn mobile-only">
				<span (click)="easyCheckout()"><a class="btn black">Book Now</a></span>
            </div>
		</div>
		
		<!--Sidebar-->
		<!-- <aside [ngClass] = "!cart?.selectedItems?.length ? 'empty':'' ">
			<app-cart [cart]="cart"></app-cart>

			<div [formGroup]="couponForm" class="desktop-only flex-container promo-code">
				<label class="pill small" for="promo-code">Promo Code</label>
				<input formControlName="promoCode" type="text" id="promo-code" placeholder="Enter Code"/>
				<button *ngIf="!cart?.offers?.length" (click)="applyPromoCode()" type="submit" class="text-link large">Apply</button>
				<button *ngIf="cart?.offers?.length" (click)="removePromoCode()" type="submit" class="text-link large">Remove</button>
			</div>
			
			<span (click)="easyCheckout()"><a class="btn black desktop-only">Book Now</a></span>
	   </aside> -->

	   <aside *ngIf="cart" [ngClass]="(bookingService.mobileCartView | async) ? 'active' : '' " >
			<div class="sidebar-sticky-desktop-only">
				<app-cart [cart]="cart"></app-cart>

				<div [formGroup]="couponForm" class="desktop-only flex-container promo-code">
					<label class="pill small" for="promo-code">Promo Code</label>
					<input formControlName="promoCode" type="text" id="promo-code" placeholder="Enter Code"/>
					<button *ngIf="!cart?.offers?.length" (click)="applyPromoCode()" type="submit" class="text-link large">Apply</button>
					<button *ngIf="cart?.offers?.length" (click)="removePromoCode()" type="submit" class="text-link large">Remove</button>
				</div>

				<span (click)="easyCheckout()"><a class="btn black desktop-only">Book Now</a></span>
			</div>
		</aside>
		
	</form>
</section>
<app-footer></app-footer>